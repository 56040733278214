<template>    
    <CRow>
        <CCol sm="12">           
            <TopHeadline :headerTitle="$t('pages.withdrawal.withdrawalDetail')" :backTitle="$t('common.back')" :onGoBack="onBackPage">
                <template v-slot:headerRight v-if="withdrawalDetail != null">
                    <button v-if="withdrawalDetail.status === 0" type="button" name="confirm-withdrawal" class="btn btn-success" @click="onConfirmToProcessing">
                        {{ $t('pages.warehouse.approve') }} <!--Peding-->
                    </button>
                    <button v-if="withdrawalDetail.status === 4" type="button" name="completed-withdrawal" class="btn btn-success" @click="onConfirmWithdrawalCompleted">
                        <!--processing-->
                        {{ $t('pages.withdrawal.moneyTransferConfirmation') }}
                    </button>
                    <button v-if="withdrawalDetail.status === 0 || withdrawalDetail.status === 4" type="button" name="cancel-withdrawal" class="btn btn-success" @click="onRejectedWithdrawal">
                        <!--Peding / processing-->
                        {{ $t('common.rejected') }}
                    </button>
                </template>
            </TopHeadline>

            <Accordion :headerTitle="$t('pages.withdrawal.withdrawalDetail')" v-if="withdrawalDetail != null">
                <template v-slot:body>
                    <CRow>
                        <CCol sm="12">
                            <CForm>
                                <CInput :label="$t('pages.deposit.transactioncode')" :value="withdrawalDetail.id" horizontal readonly></CInput>
                                <CInput :label="$t('common.amount')" :value="$func.formatCurrency(withdrawalDetail.amount)" horizontal readonly></CInput>
                                <CInput :label="$t('pages.withdrawal.fee')" :value="withdrawalDetail.fee" horizontal readonly></CInput>
                                <CInput :label="$t('pages.withdrawal.totalAmount')" :value="$func.formatCurrency(withdrawalDetail.amountAfterFee)" horizontal readonly></CInput>
                                <CInput :label="$t('pages.bank.accountName')" :value="withdrawalDetail.accountName" horizontal readonly></CInput>
                                <CInput :label="$t('pages.bank.accountNo')" :value="withdrawalDetail.accountNo" horizontal readonly></CInput>
                                <CInput :label="$t('pages.bank.bankName')" :value="withdrawalDetail.bankName" horizontal readonly></CInput>
                                <CInput :label="$t('pages.bank.branch')" :value="withdrawalDetail.branch" horizontal readonly></CInput>
                                <CInput :label="$t('common.createdDate')" :value="formatDate(withdrawalDetail.createdDate)" horizontal readonly></CInput>
                                <CInput :label="$t('common.status')" :value="withdrawalStatus(withdrawalDetail.status)" horizontal readonly></CInput>

                                <CTextarea :placeholder="$t('pages.deposit.reasonForReject')" v-model="reasonForReject" v-if="withdrawalDetail.status == 0" horizontal>
                                    <template #label>
                                        <div class="col-sm-3 col-form-label">
                                            {{ $t('pages.deposit.contents') }}
                                        </div>
                                    </template>
                                </CTextarea>
                            </CForm>
                        </CCol>                        
                    </CRow>
                </template>
            </Accordion>

            <Confirmation ref="confirmation"></Confirmation>
        </CCol>        
    </CRow>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import i18n from '@/plugins/i18n'
    import Confirmation from '@/components/Confirmation.vue'
    import Accordion from '@/components/Accordion.vue'
    import WithdrawalStatus from '@/helpers/WithdrawalStatus'
    import TopHeadline from '@/components/TopHeadline.vue'
    
    export default {
        name: 'WithdrawalDetail',
		data() {            
			return {                                
                warningModal: false,
                reasonForReject: ''
            };
		},
        components: {           
            Confirmation,
            Accordion,
            TopHeadline
        },
        computed: {           
            ...mapState('withdrawal', ['withdrawalDetail']),
        },
        methods: {
            ...mapActions('withdrawal', ['getWithdrawalById', 'changeWithdrawalStatus']),
            formatDate(date) {
                if (date === undefined)
                    return '';
                return this.$moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss');
            },
            withdrawalStatus(iStatus) {

                if (WithdrawalStatus.Completed === iStatus)
                    return i18n.t('common.completed');
                else if (WithdrawalStatus.Rejected === iStatus)
                    return i18n.t('common.rejected');
                else if (WithdrawalStatus.Canceled === iStatus)
                    return i18n.t('common.canceled');
                else if (WithdrawalStatus.Processing === iStatus)
                    return i18n.t('common.processing');
                else
                    return i18n.t('common.pending');
               
            },
            //showButtonToApprove() {                                
            //    if (!this.withdrawalDetail)
            //        return false;
            //    return this.withdrawalDetail.status == WithdrawalStatus.Pending;
            //},
            //showButtonToCompleted() {
                
            //    if (!this.withdrawalDetail)
            //        return false;
            //    return this.withdrawalDetail.status == WithdrawalStatus.Processing;
            //},
            //showRejectedButton() {
            //    if (!this.withdrawalDetail)
            //        return false;
            //    return (this.withdrawalDetail.status === WithdrawalStatus.Rejected || this.withdrawalDetail.status === WithdrawalStatus.Processing);
            //},
            async onConfirmToProcessing() {               
                var retVal = await this.changeWithdrawalStatus({ withdrawalId: this.withdrawalDetail.id, newStatus: WithdrawalStatus.Processing });
                if (retVal == true) {
                    this.onBackPage();
                }
            },
            async onConfirmWithdrawalCompleted() {
                
                this.$refs.confirmation.show(i18n.t('pages.withdrawal.confirmChangeStatusWithdrawal'), async () => {
                    var retVal = await this.changeWithdrawalStatus({ withdrawalId: this.withdrawalDetail.id, newStatus: WithdrawalStatus.Completed });
                    if (retVal == true) {
                        this.onBackPage();
                    }
                });
            },
            async onRejectedWithdrawal() {
               
                this.$refs.confirmation.show(i18n.t('pages.withdrawal.confirmRejectWithdrawal'), async () => {

                    if (this.reasonForReject === '') {
                        this.$store.dispatch('alert/error', this.$t('pages.withdrawal.reasonForRejectContents'));                       
                    }
                    else {
                        var retVal = await this.changeWithdrawalStatus({ withdrawalId: this.withdrawalDetail.id, newStatus: WithdrawalStatus.Rejected, reasonForReject: this.reasonForReject });
                        
                        if (retVal == true) {
                            this.onBackPage();
                        }
                    }
                });
            },
            onBackPage() {               
                this.$router.push('/assets/withdrawals');
            },
		},       
        created() {
            this.getWithdrawalById(this.$route.params.id);
        }
	}
</script>

